<template>
  <div>
    <b-table
      :items="displayResults"
      :fields="fields"
      :busy="isLoading"
      responsive=""
      small
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" />
        </div>
      </template>
      <template #cell(stt)="data">
        {{ data.index + 1 }}
      </template>

      <template #cell(side)="data">
        {{ TRANSFER_SIDES[data.item.side] }}
      </template>

      <template #cell(status)="data">
        {{ STATUS[data.item.status] }}
      </template>

      <template #cell(occur_date)="data">
        {{ data.item.occur_date | local_time_string }}
      </template>

      <template #cell(action)="data">
        <b-button
          variant="gradient-warning"
          style="margin-right: 5px"
          class="btn-icon"
          @click="openDetailModal(data.item)"
        >
          <feather-icon icon="EyeIcon" />
        </b-button>
      </template>
    </b-table>
    <transfer-form-modal ref="modalTransferForm" />
  </div>
</template>

<script>
import TransferFormModal from "./TransferNftFormModal.vue";
const STATUS = ['INACTIVE', 'ACTIVE', 'PENDING']
const TRANSFER_SIDES = ['OUT', 'IN']

export default {
  components: {
    TransferFormModal,
  },
  props: {
    displayResults: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: [
        "STT",
        "user_id",
        "source_id",
        "side",
        "status",
        "occur_date",
        "action",
      ],
      STATUS,
      TRANSFER_SIDES
    };
  },
  methods: {
    getTitle(url) {
      return url ? url.slice(8) : "";
    },
    openDetailModal(updateData) {
      this.$refs.modalTransferForm.openDetailModal(updateData);
    },
  },
};
</script>

<style>
</style>